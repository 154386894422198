import React, { useState, useEffect } from 'react';

function App() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Chiamata all'API Laravel con percorso relativo
    fetch('/api/users')  // Percorso relativo
      .then(response => response.json())
      .then(data => setUsers(data))
      .catch(error => console.error('Errore:', error));
  }, []);

  return (
    <div className="App">
      <h1>Elenco Utenti</h1>
      <ul>
        {users.map(user => (
          <li key={user.id}>
            {user.name} - {user.email}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default App;
